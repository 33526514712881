import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,NavigationStart } from '@angular/router';


import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { HeaderService } from '../../header/header-two/header.service';
import metaData from 'src/assets/data/default/header-footer.json';
import { config }  from 'src/configs/config';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import { LoginService } from 'src/app/Lazy-loaded-Modules/Account-Modules/login-new/login-new.service';
import { ProductListingService } from 'src/app/Lazy-loaded-Modules/Product-Modules/product-lists/product-listing/product-listing.service';
import { AlertsService } from 'src/app/Components/alerts/alerts.service';
import {HostListener } from '@angular/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-luckme-footer',
  templateUrl: './footer-luckme.component.html'
})
export class FooterLuckmeComponent implements OnInit {
  windowscroll()
  {
    window.scrollTo(0,0)
  }

  copyRightText:any = "";
  config=config;
  socialMediaData:any = [];
  facebookLink;
  instagramLink;
  pinterestLink;
  twitterLink;
  linkedlnLink;
  //onLineShopping = metaData.footer;
  onLineShopping:any=[];
  address:any=[];
  public outletId;
  public address1;
  public email;
  public phone;

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  public outletData;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private localService: LocalStorageService,
    public appService: AppService,
    private LoginService:LoginService,
    public ProductListingService:ProductListingService,
    private alertService: AlertsService,
    // private appService : AppService
  ) { 
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: any) => {
      //console.log(event.url,"event.url123")
      
     ;
      this.outletId = this.appService.outletId;
      if(this.outletId==1){
        this.outletId = this.localService.get('karupattirajaOutletId');
      }
      console.log(this.route.url,"this.route.url",this.outletId)
      console.log(this.route.url,"this.route.url",this.appService.outletId)
      if(this.config.title=="Karupattiraja"){
        if(this.outletId=="0"|| this.outletId==0||this.outletId==""|| this.outletId==null){
          this.outletId=1;
        }
        
        
      }
      this.getOutletData();
      
     })
  }

  ngOnInit(): void {
    // this.getCopyRightText();
    this.outletId = this.appService.outletId;
    if(this.outletId==1){
      this.outletId = this.localService.get('karupattirajaOutletId');
    }
    if(this.config.title=="Karupattiraja"){
      if(this.outletId=="0"|| this.outletId==0||this.outletId==""|| this.outletId==null ){
        this.outletId=1;
      }
      
    }
    this.getSocialMediaDetails();
    this.getAllCategories();
    if(this.config.title==="Karupattiraja"){
      this.getOutletData();
    }
  }
  getAllCategories() {
    console.log("inside cate")

	
    if(this.config.title==="Carience" || this.config.title==="ShubhCards"){
      let reqData2 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        // "outlet_id":Number(this.outletId),
        // "outlet_id":"",
        "extras": {
          "find": {
            // "outlet_id":this.outletId,
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "sort_order",
            "sortorder": -1
          }
        }
      }
      
      this.appService.postApiCall(reqData2, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
        resp => {
  // alert('1')
  // alert(JSON.stringify(resp))
          if(resp && resp.success == 1)
          {
            resp.result.data.forEach(element=>
              {
               // alert(JSON.stringify(element))
                this.onLineShopping.push({"name":element.name})
              })
          }
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })

    }
    else{
     
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "outlet_id":Number(this.outletId),
        // "outlet_id":"",
        "extras": {
          "find": {
            // "outlet_id":this.outletId,
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "name",
            "sortorder": -1
          }
        }
      }
  
      let reqData1 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "outlet_id":1,
        // "outlet_id":"",
        "extras": {
          "find": {
            // "outlet_id":this.outletId,
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "name",
            "sortorder": -1
          }
        }
      }
  
     
      if(this.outletId){
        this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
          resp => {
    // alert('1')
    // alert(JSON.stringify(resp))
            if(resp && resp.success == 1)
            {
              resp.result.data.forEach(element=>
                {
                 // alert(JSON.stringify(element))
                  this.onLineShopping.push({"name":element.name})
                  console.log( this.onLineShopping,' this.onLineShopping')
                })
            }
          },
          err => {
            if (err.error.error.message) {
              console.log(err.error.error.message)
            }
            else {
              console.log('Something bad happened; Please try again!')
            }
          })
  
      }
      else{
        this.appService.postApiCall(reqData1, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
          resp => {
    // alert('1')
    // alert(JSON.stringify(resp))
            if(resp && resp.success == 1)
            {
              resp.result.data.forEach(element=>
                {
                 // alert(JSON.stringify(element))
                  this.onLineShopping.push({"name":element.name})
                  
                })
                console.log(this.onLineShopping)
            }
          },
          err => {
            if (err.error.error.message) {
              console.log(err.error.error.message)
            }
            else {
              console.log('Something bad happened; Please try again!')
            }
          })
      }


      
    }
	}
  navigateToProduct(link :any){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    if (config.title == 'ShubhCards' || config.title == 'BestGifts') {
      console.log("linklinklinklinklinklinklink"+link)
      this.localService.set('is_category_filter','true')
      this.localService.set('category_filter_name',link)
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });


    }
    else if (config.title === 'Carience'||config.title === 'Karupattiraja') {
      console.log("Karupattiraja",link)
      console.log("linklinklinklinklinklinklink"+link)
      let userId = this.localService.get('UserId');
      if(userId){
      // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      }
      else{
        this.LoginService.updateAdminFormEvent({name: "ask_login"});
        this.router.navigate([`/login-new`], { skipLocationChange: false });
       //this.router.navigate([`/login-new`], { skipLocationChange: false });
      }
     }
     else if(config.title === 'Deera'){
      console.log("fnbnb")
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
     }
     else{
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

     }
   
   // this.router.navigate([`/productListing`], { queryParams: {terms:link, type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
  }
  // productListing(){
	// 	this.router.navigate([`/productListing`], { queryParams: {term:'', type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });

  //   }

  productListing(){
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }
  navigateToProductTheme(list : any){
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[list],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }
  getBrochure(){
    //this.headerService.updateAdminFormEvent('clearSearchTerm');
    window.open("http://d1bo46l1lnh354.cloudfront.net/Mahaveer/Brochure/ITR-Lubricants-Brochure.pdf")
  }


  getSocialMediaDetails(){
   
    // REQUEST DATA
    let request_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {}
      }
    }
    // MAKE AN API CALL
    this.appService.postApiCall(request_data, ENDPOINTS.STORE_GALLERY).subscribe(  //LINE 
      resp => {
        let respData = resp.result.data[0];
        if(respData.social){
          if(respData.social.media.length > 0){          
            this.socialMediaData = respData.social.media;
          }
          
          this.socialMediaData.forEach(social => {
           if(social.media_name === 'Facebook' && social.media_link !== ''){
              this.facebookLink = social.media_link
           } else if(social.media_name === 'Twitter' && social.media_link !== ''){
            this.twitterLink = social.media_link
           } else if(social.media_name === 'Instagram' && social.media_link !== ''){
            this.instagramLink = social.media_link
           } else if(social.media_name === 'Linkedln' && social.media_link !== ''){
            this.linkedlnLink = social.media_link
           } else if(social.media_name === 'Pinterest' && social.media_link !== ''){
            this.pinterestLink = social.media_link
           }
          
        });
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  getLatestProducts(){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    if (config.title === 'Carience' ||config.title === 'Karupattiraja' ) {
      let userId = this.localService.get('UserId');
      if(userId){
        window.scroll(0,0)
        //this.ProductListingService.updateAdminFormEvent({name: "know_more"}); 
      // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
       
    
    }
     
      else{
        this.LoginService.updateAdminFormEvent({name: "ask_login"});
       this.router.navigate([`/login-new`], { skipLocationChange: false });
      }
     }
     else if(config.title === 'BestGifts' ||config.title === 'Deera'){
      window.scroll(0,0)
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

    }
    else if(config.title === 'Deera'){
      window.scroll(0,0)
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

    }
     else{
      this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });

     }
     }

  windowOpen(social){
    window.open(social)
  }

  // getCopyRightText(){
  //   // REQUEST DATA
  //   let request_data = {
  //     "domain_name": "www.mahaveerdistributor.in",
  //     "user_id": 17,
  //     "extras": {
  //         "find": {}
  //     }
  //   }
  //   // MAKE AN API CALL
  //   this.appService.postApiCall(request_data, ENDPOINTS.STORE_SETTINGS).subscribe(
  //     resp => {
  //       let respData = resp.result.data[0];
  //       if(respData.website){
  //         this.copyRightText = respData.website.copyright_text;
  //       }
  //     },
  //     err => {
  //       if (err.error.error.message) {
  //         console.log(err.error.error.message)
  //       }
  //       else{
  //         console.log('Something bad happened; Please try again!')
  //       }
  //     })
  // }
  productsListTazTest(){
    document.getElementById("mySidenav").style.width = "0";
    this.router.navigate(['/productListing']);
  }
  productsListTazTest1(category){
    window.scrollTo({top: 0, behavior: 'smooth'});
    const params = category.split('/').map(param => param.replace(/\s+/g, '-'));
    console.log("URL BBJAH", params)
    this.router.navigate(['/productListing', ...params]);
  }
  getOutletData(){
    if(this.outletId){
      this.outletData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "outlet_id":Number(this.outletId),
        "extras": {
          "find": {
            "id":Number(this.outletId)
          }
        }
      }
    } else {
      this.outletData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "id":1
          }
        }
      }
    }

    this.appService.postApiCall(this.outletData, ENDPOINTS.GET_OUTLETS).subscribe(
      resp => {
        if(resp.success === 1){
          this.address=resp.result.data[0];

          this.address1=resp.result.data[0].address1;   
          this.email = resp.result.data[0].email;   
          this.phone = resp.result.data[0].phone;         
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }        
        
        //hiding book  a table//
       
        
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }
}
